.create-bill-form__section-container {
  margin-top: 30px;
}

.create-bill-form__section {
  display: flex;
  gap: 82px;

  width: max-content;

  margin-top: 16px;
  padding: 24px;

  background-color: white;
  border: 1px solid var(--button-dark-secondary);
  border-radius: 4px;

  .form-field__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .typeahead-select__header-container,
  .date-input-container,
  .input-container {
    width: 340px;
  }

  .create-bill-form__checkbox {
    width: 16px;
    height: 16px;
  }
}

.create-bill-form__checkbox-label {
  .form-field__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.create-bill-form__expire-date-container {
  display: flex;
  align-items: center;
  gap: 12px;

  .date-input-container {
    width: 156px;
  }
}

.create-bill-form__tax-input {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 340px;
}

.create-bill-form__button-group {
  position: fixed;
  bottom: 48px;
  right: 28px;
}
