.create-label-form {
  display: flex;
  flex-direction: column;

  margin-top: 24px;
}

.create-label-form__color-list {
  .list {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(7, auto);
    gap: 20px;

    padding: 20px 28px;

    background-color: var(--bg-primary);
    border: 1px solid var(--stroke-grey);
    border-radius: 4px;
  }
}

.create-label-form__color {
  width: 24px;
  height: 24px;

  border: 1px solid var(--button-primary-hover);
  border-radius: 4px;

  cursor: pointer;

  &--is-active {
    border: 2px solid var(--button-primary-hover);
  }
}
