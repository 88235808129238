@import "../../../typography/_typography-mixins.scss";

.button {
  @include typography--h8();

  --button-bg: white;
  --button-height: 32px;
  --button-color: var(--text-primary);

  padding: 8px 12px;

  border-radius: 4px;

  transition: background-color var(--default-animation);

  &:focus {
    box-shadow: unset;
  }
}

.button--primary {
  --button-bg: var(--button-primary);
  --button-color: var(--text-light);

  &:hover {
    --button-bg: var(--button-primary-hover);
  }
}

.button--secondary {
  --button-bg: var(--button-secondary);

  &:hover {
    --button-bg: var(--button-secondary-hover);
  }
}

.button--dark-primary {
  --button-bg: var(--button-dark-primary);

  &:hover {
    --button-bg: var(--button-dark-hover);
  }
}

.button--blue {
  --button-bg: var(--button-blue);

  &:hover {
    --button-bg: var(--button-blue-hover);
  }
}

.button--transparent {
  --button-bg: transparent;

  color: var(--text-secondary);

  transition: color var(--default-animation);

  &:hover {
    color: black;
  }
}

.button--medium {
  height: 32px;

  padding: 8px;
}

.button--large {
  height: 44px;

  padding: 14px 36px;
}

.button-container {
  display: flex;
  gap: 12px;
}
