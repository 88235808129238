.edit-truck-payment-form {
  width: 440px;

  margin-top: 24px;

  .form-field__label {
    grid-template-columns: unset;
    gap: unset;
  }
}

.edit-truck-payment-form__period-field {
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 10px;

  input {
    width: 42px;
  }

  .input-container {
    width: unset;
  }
}

.edit-truck-payment-form__price-field {
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 10px;

  input {
    width: 100px;
  }

  .input-container {
    width: unset;
  }
}

.edit-truck-payment-form__amount-field {
  display: flex;
  flex-direction: row;

  input {
    width: 90px;
  }
}

.edit-truck-payment-form__date-field {
  .date-input-container {
    margin-top: 10px;
  }
}

.edit-truck-payment-form__radio-button-group {
  display: flex;
  flex-direction: row;
  gap: 36px;

  margin-top: 8px;
  margin-bottom: 24px;
}

.edit-truck-payment-form__radio-button-container {
  display: flex;
  flex-direction: row;

  cursor: pointer;
}

.edit-truck-payment-form__radio-button__input {
  width: 16px;
  height: 16px;

  margin-right: 12px;

  accent-color: black;
}

.edit-truck-payment-form__button-group {
  display: flex;
  justify-content: flex-end;

  margin-top: 36px;
}

.edit-truck-payment-form__delete-button {
  height: 36px;

  margin-right: auto;
}
