.table {
  min-width: 992px;

  border-radius: 4px;
}

.table-header,
.table-row {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  padding: 20px 24px;

  color: var(--text-primary);
  background-color: white;
  border-bottom: 1px solid var(--stroke-grey);

  text-align: center;

  transition: background-color var(--default-animation);

  &:last-child {
    border-bottom: unset;
  }

  &:hover {
    background-color: var(--button-dark-secondary);
  }

  &:visited {
    color: var(--text-primary);
  }
}

.table-header {
  &:hover {
    background-color: white;
  }
}

.table-body {
  div {
    align-items: center;
  }
}

.table__empty-view-container {
  height: 70px;

  background-color: white;
}
