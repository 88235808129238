@import "../../../core/ui/typography/_typography-mixins.scss";

.create-edit-tour-form {
  .input-container,
  .typeahead-select__header-container {
    width: 240px;
  }
}

.create-edit-tour-form__initial-view {
  display: flex;
  align-items: center;
  gap: 10px;

  .button {
    @include typography--h3();

    color: var(--text-blue);
  }
}
