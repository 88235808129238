@import "../../../typography/_typography-mixins.scss";

.input-container {
  position: relative;
}

.input {
  @include typography--h5();

  --input-height: 32px;

  padding: 8px 12px;

  color: var(--text-primary);

  background-color: white;
  border-color: var(--stroke-grey);
  border-radius: 4px;
}

.input::placeholder {
  @include typography--b3();

  color: var(--text-secondary);
}

.textarea {
  padding: 8px 12px;

  color: var(--text-primary);

  background-color: white;
  border-color: var(--stroke-grey);
  border-radius: 4px;
}

.input-container__left-icon {
  position: absolute;
  left: 8px;

  width: 12px;

  & + input {
    padding-left: 24px;
  }
}
