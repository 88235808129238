.bill-filter-form {
  .form-field__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.bill-filter-form__range-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bill-filter-form__button-group {
  display: flex;
  justify-content: space-between;
}
