.login-page {
  display: flex;
  justify-content: space-between;

  background-color: var(--bg-primary);
}

.login-page__form-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
}

.login-page__form {
  width: 350px;
}

.login-page__form-field {
  .form-field__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: unset;
  }
}

.login-page__form__title {
  margin-bottom: 32px;
}

.login-cover {
  width: 50%;

  object-fit: cover;
}

.login-page__password-input {
  &.password-input {
    .input-container__right-icon {
      top: 3px;
      
      height: max-content;
    }
  }

  .password-input__icon {
    height: 28px;

    padding: 0;
  }
}
