.create-tour-form-container {
  background-color: white;
}

.create-tour-form {
  display: flex;
  gap: 80px;

  margin-top: 32px;
}

.create-tour-form__form-field {
  &--note {
    .form-field__label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.create-tour-form__note-textarea {
  width: 380px;
  height: 180px;
}

.create-tour-form__button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  gap: 12px;

  margin-top: 80px;
}
