.employee-detail-page-document {
  width: 100%;
}

.employee-detail-page-document__header {
  height: 52px;
}

.employee-detail-page-document__table {
  height: 320px;

  margin-top: 20px;

  &.table {
    min-width: unset;
  }

  .table-body {
    height: 240px;

    overflow-y: scroll;
  }

  .table-header {
    grid-template-columns: 1fr 1fr 96px;
    gap: 12px;
  }
}

.employee-detail-page-document__table__row {
  .list-item__click-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 96px;
    gap: 12px;

    height: 58px;

    padding: 18px 24px;

    background-color: white;
    border-bottom: 1px solid var(--stroke-grey);

    text-align: center;
  }
}
