.typeahead-select__input {
  width: min-content;
  height: 32px;

  .input {
    width: 100%;

    border-color: transparent;
  }

  .input-container__icon {
    margin-left: auto;
  }
}

.typeahead-select__header-container {
  background-color: white;
  border: 1px solid var(--stroke-grey);
  border-radius: 4px;
}

.typeahead-select-header__tag-list {
  top: 0;
  left: 0;

  gap: 2px;

  width: 360px;

  padding: 4px;
}

.typeahead-select-header__tag-list__item {
  margin: 0;
}

.typeahead-select-header__tag-list__item__tag {
  height: 32px;

  background-color: var(--button-dark-secondary);
  border-radius: 4px;
}

.typeahead-select--can-select-multiple .input {
  min-height: 32px;
}
