.create-credit-form__form-field-group {
  display: flex;
  gap: 32px;

  margin-top: 24px;

  .form-field__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .typeahead-select,
  .date-input-container {
    width: 340px;
  }
}

.create-credit-form__checkbox-label {
  margin-top: 32px;

  .form-field__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.create-credit-form__checkbox {
  width: 16px;
  height: 16px;
}

.create-credit-form__tax-input {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 340px;
}
