/* stylelint-disable declaration-no-important */

.recharts-tooltip-wrapper {
  border: unset !important;
  outline: unset !important;
}

.chart-tooltip {
  padding: 10px 8px;

  background-color: rgba(21, 21, 21, 0.92);
  border-radius: 4px;
}

.chart-tooltip-item {
  display: flex;
  gap: 4px;

  margin-top: 4px;

  p {
    color: white;
  }
}

.chart-tooltip-dot {
  width: 8px;
  height: 8px;

  margin: auto 0;

  border-radius: 50%;
}
