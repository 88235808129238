@import "../core/ui/typography/_typography-mixins.scss";

.home-page {
  display: grid;
  grid-template-columns: 600px auto;
  gap: 18px;

  .table {
    min-width: unset;
    max-height: 340px;
  }
}

.home-page__chart-section {
  margin-bottom: 20px;
  padding: 28px;

  background-color: white;
  border-radius: 4px;
}

.home-page-tour-table-container {
  overflow-y: auto;

  padding: 24px;

  background-color: white;
}

.home-page-tour-table {
  .table-header,
  .table-row {
    padding: 12px 0;
  }

  .table-header {
    color: var(--text-secondary);
  }

  .table-row {
    @include typography--h5();
  }
}

.home-page-right-side {
  position: relative;

  height: max-content;
}

.home-page-right-table-section {
  padding-top: 24px;

  background-color: white;
}

.home-page-right-title {
  position: absolute;

  top: 26px;
  left: 60px;
}

.home-page-right-tab {
  .tab__header {
    justify-content: flex-end;

    margin-right: 64px;
  }

  .tab-header-item {
    @include typography--h3();

    color: var(--text-secondary);

    &--is-active {
      color: var(--text-primary);
    }

    &:not(:first-of-type) {
      margin-left: 12px;
    }
  }
}

.home-page-right-table-container {
  height: 320px;

  overflow-y: auto;

  .table-row {
    @include typography--h5();
  }
}

.home-page-right-tables {
  margin: 0 24px;

  .table-header {
    padding: 8px;

    border-bottom: unset;
  }

  .table-header-item {
    @include typography--h4();

    width: max-content;

    margin: auto;
    padding-bottom: 4px;

    // stylelint-disable color-no-hex, color-hex-case
    border-bottom: 1px solid #02c98a;
  }

  .table-row {
    padding: 16px;
  }
}

.payment-section {
  margin-top: 20px;
  padding: 24px;

  background-color: white;
  border-radius: 4px;

  h3 {
    margin-bottom: 20px;
  }
}

.payment-container {
  display: flex;
  gap: 28px;
}

.payment-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  width: 100%;

  padding: 16px;

  border-radius: 4px;

  &--credit {
    background: linear-gradient(135.33deg, #0241c9 25%, rgba(2, 65, 201, 0.6) 100%);
  }

  &--bill {
    background: linear-gradient(116.69deg, #02a4c9 0%, rgba(2, 164, 201, 0.6) 100%);
  }
}
