@import "../../../../core/ui/typography/_typography-mixins.scss";

.table-header-item--is-active {
  display: flex;
  align-items: center;

  color: var(--text-primary);

  &:hover,
  &:focus {
    color: var(--text-primary);
  }

  .ordering-icon path {
    stroke: var(--text-primary);
  }
}

.table-header-item {
  .button {
    @include typography--h4();
  }
}

.table-header-item--direction--desc {
  .table-header-item__ordering-icon {
    transform: rotate(180deg);
  }
}

.table-header-item__ordering-icon {
  margin-left: 2px;

  path {
    fill: var(--secondary);
  }
}

.table-header-item__button {
  margin: 0 auto;
}
