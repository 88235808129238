@import "../../../core/ui/typography/_typography-mixins.scss";

.date-input-container {
  position: relative;

  width: 100%;
}

.date-input {
  @include typography--b2();

  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 32px;

  padding: 8px;

  background-color: white;
  border: 1px solid var(--stroke-grey);
  border-radius: 4px;
}

.date-input-icon {
  width: 0;
  height: 0;
}

.date-input-button {
  position: absolute;
  top: 1px;
  right: 0;

  background-color: transparent;

  .calendar-icon path {
    stroke: var(--icon-grey);
  }
}
