.employee-detail-page-header__breadcrumb {
  display: flex;
  justify-content: space-between;

  width: max-content;

  .arrow-right-icon {
    width: 24px;
    height: 24px;

    margin: 0 12px;
  }

  // TODO: Reorganize `active` classes

  .active {
    padding: unset;

    background-color: unset;
  }
}

.employee-detail-page-header__button-group {
  gap: 24px;
}
