/* stylelint-disable declaration-no-important */

.employee-page__table__row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  padding: 20px 24px;

  background-color: white;

  text-align: center;

  transition: background-color var(--default-animation);

  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }

  &:hover {
    background-color: var(--button-dark-secondary);
  }

  &,
  &:visited {
    color: var(--text-primary);
  }
}

.employee-filter-checkbox {
  .form-field__label {
    justify-content: flex-start !important;
  }
}
