.create-driver-form {
  width: 340px;

  .form-field__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .typeahead-select__header-container {
    width: 340px;
  }
}

.create-driver-form__checkbox-label {
  .form-field__label {
    display: flex;
    flex-direction: row-reverse;
  }
}

.create-driver-form__checkbox {
  width: 16px;
  height: 16px;
}
