.tour-page-header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 28px;
}

.tour-page-header__month-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tour-page-header__month-button {
  height: 24px;

  padding: unset;

  .arrow-right-icon,
  .arrow-left-icon {
    width: 24px;
    height: 24px;

    background-color: var(--bg-primary);
  }
}

.tour-page-header__filter {
  .settings-icon {
    margin-left: 4px;
  }
}
