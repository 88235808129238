@mixin typography--h1() {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

@mixin typography--h2() {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
}

@mixin typography--h3() {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}

@mixin typography--h4() {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}

@mixin typography--h5() {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
}

@mixin typography--h6() {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
}

@mixin typography--h7() {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 300;
  line-height: 120%;
}

@mixin typography--h8() {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}

@mixin typography--h9() {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
}

@mixin typography--b1() {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 300;
  line-height: 120%;
}

@mixin typography--b2() {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

@mixin typography--b3() {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}

@mixin typography--b4() {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 300;
  line-height: 120%;
}

@mixin typography--b5() {
  font-family: "Montserrat";
  font-size: 11px;
  font-weight: 400;
  line-height: 120%;
}

@mixin typography--b6() {
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 300;
  line-height: 120%;
}
