.dropdown {
  border: 1px solid var(--default-border-color);
  border-radius: 4px;
}

.dropdown-list {
  &.bottom {
    bottom: 0;
  }
}

.dropdown__header-button {
  height: 34px;

  border: unset;
}
