@import "../../../core/ui/typography/_typography-mixins.scss";

.create-truck-document-form {
  .form-field__label {
    gap: 6px;

    .create-truck-document-form__drag-drop {
      gap: 12px;

      height: 180px;

      padding: 20px;

      span {
        @include typography--b1();
      }
    }
  }
}

.create-truck-document-form__drag-drop-files {
  @include typography--h4();

  margin-top: 20px;
}
