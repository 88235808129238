:root {
  --black: #000000;
  --white: #FFFFFF;

  --grey-10: #F8F8F8;
  --grey-20: #EBEBEB;
  --grey-30: #D2D2D2;
  --grey-40: #B8B8B8;
  --grey-50: #787878;
  --grey-60: #454545;
  --grey-70: #2C2C2C;
  --grey-80: #121212;

  --purple-50: #6C257E;

  --cyan-50: #0B7285;
}
