@import "../../../typography/_typography-mixins.scss";

.form-field {
  @include typography--h7();

  margin-bottom: 24px;
}

.form-field__label {
  display: grid;
  align-items: center;
  grid-template-columns: 120px 1fr;
  gap: 12px;
}

.form-field__title {
  margin-bottom: unset;
}

.form__button-group {
  display: flex;
  justify-content: flex-end;

  margin-top: 36px;
}

.form-field--vertical {
  .form-field__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
