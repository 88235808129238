.default-theme {
  --bg-primary: #f8f9fd;
  --bg-secondary: #151515;
  --bg-note: #fffcdd;

  --text-primary: #151515;
  --text-secondary: #9b9b9b;
  --text-light: #dcdcdc;
  --text-white: #ffffff;
  --text-blue: #0241c9;
  --text-info: #e87c2d;
  --text-error: #e82d2d;

  --button-primary: #323232;
  --button-primary-hover: #151515;
  --button-secondary: #ecf2ff;
  --button-secondary-hover: #d5e2ff;
  --button-blue: #ecf2ff;
  --button-blue-hover: #d5e2ff;
  --button-dark-primary: #9b9b9b;
  --button-dark-secondary: #f2f2f2;

  --tag-green-1: #d9f3d6;
  --tag-green-2: #c8ffde;
  --tag-blue-1: #ddf7f9;
  --tag-blue-2: #d6d9f1;
  --tag-blue-3: #bee0ff;
  --tag-purple-1: #d5e2ff;
  --tag-purple-2: #dad6f3;
  --tag-pink-1: #f3d6f0;
  --tag-pink-2: #fdeaf5;
  --tag-pink-3: #ffcdcf;
  --tag-orange-1: #ffcc9c;
  --tag-orange-2: #ffe1b5;
  --tag-yellow-1: #fffcdd;
  --tag-yellow-2: #feef94;

  --stroke-grey: #dcdcdc;

  --icon-grey: #9b9b9b;

  --disabled-grey: #e4e4e4;
}
