.label-tab {
  display: flex;
  justify-content: space-around;
  gap: 30px;

  background-color: var(--white);
  border-radius: 12px;
}

.label-tab__table-container {
  height: 100%;

  overflow-y: auto;
}

.label-tab__table__title {
  margin: 16px 0;

  text-align: center;
}

.label-tab__table__button {
  margin: 0 24px 12px auto;
}

.label-tab__table {
  &.table {
    width: 300px;
    min-width: unset;
  }

  .table-body {
    height: 430px;

    overflow-y: scroll;
  }
}

.label-tab__name {
  width: max-content;

  margin: auto;
  padding: 4px 6px;

  border: 1px solid var(--stroke-grey);
  border-radius: 4px;
}

.label-tab__color {
  width: 24px;
  height: 24px;

  margin: auto;

  border: 1px solid var(--button-primary-hover);
  border-radius: 4px;
}

.label-tab__table__row {
  .list-item__click-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.table-row {
    &:last-child {
      border-bottom: unset;
    }
  }
}
