.company-tab {
  display: inline-block;

  width: 100%;

  background-color: var(--white);
}

.company-tab__table {
  min-width: unset;

  .table-header {
    grid-template-columns: 1fr 1fr 2fr;

    padding: 20px 24px;
  }

  .company-tab__table__row {
    padding: 0;

    .list-item__click-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;

      padding: 20px 24px;
    }
  }
}

.company-tab__cta {
  margin: 32px 0 32px 44px;
}

.create-company-form {
  .form-field__label {
    grid-template-columns: unset;
  }
}
