.page-navigation {
  position: fixed;
  z-index: 1;

  width: var(--page-navigation-width);
  height: 100vh;

  padding-top: 40px;

  background-color: var(--bg-secondary);

  transition: all var(--default-animation);

  &--close {
    --page-navigation-width: 96px;

    .page-navigation__elbcargo,
    .page-navigation__elbcargo__change-button,
    .page-navigation__nav-link-list,
    .page-navigation__user {
      visibility: hidden;
      opacity: 0;
    }

    .page-navigation__collapse-button {
      .arrow-left-icon {
        transform: rotate(180deg);
      }
    }

    .page-navigation__elbcargo-logo {
      top: 180px;
    }
  }
}

.page-navigation__elbcargo {
  display: grid;
  grid-template-columns: 145px auto;

  margin: 16px 36px 0 24px;
}

.page-navigation__collapse-button {
  position: absolute;
  right: 0;

  width: 56px;
  height: 56px;

  padding: 0;

  background-color: white;
  border-radius: 50%;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.04);

  transform: translateX(50%);

  .arrow-left-icon {
    width: 56px;
    height: 56px;
  }
}

.page-navigation__elbcargo-logo {
  position: relative;
  top: 0;

  width: 50px;
  height: 50px;

  margin: 40px 0 0 24px;

  object-fit: contain;

  transition: top var(--default-animation);
}

.page-navigation__elbcargo__change-button {
  margin-left: 8px;

  &:hover {
    text-decoration-line: underline;
  }
}

.page-navigation__nav-link-list {
  display: flex;
  flex-direction: column;

  margin-top: 40px;
}

.page-navigation__user {
  display: flex;

  margin: 56px 0 0 24px;
}
