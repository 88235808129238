.create-admin-form__company-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-top: 24px;
}

.create-admin-form__company {
  .form-field__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
