.vacation-management-header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 24px;
}

.vacation-management-header__search {
  width: 260px;
}

.vacation-management-schedule-container {
  width: max-content;

  margin: 0 auto;
}

.vacation-management-schedule {
  display: flex;

  width: max-content;
}

.vacation-management-schedule__header {
  display: flex;
  flex-direction: row;
}

.vacation-management-schedule__header-row {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 76px;
  height: 70px;

  background-color: white;
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);

  &:first-child {
    border-left: 1px solid var(--stroke-grey);
  }
}

.vacation-management-schedule__index {
  position: relative;

  width: 118px;
  height: 70px;

  background-color: var(--white);
  border-bottom: 1px solid var(--stroke-grey);

  &--row {
    position: absolute;
    top: 4px;
    right: 8px;
  }

  &--column {
    position: absolute;
    bottom: 8px;
    left: 4px;
  }
}

.vacation-management-schedule__list {
  width: max-content;
}

.vacation-management-schedule__list-item-container {
  display: flex;
}

.vacation-management-schedule__list-item {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;

  background: var(--white);
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);

  text-align: center;

  &--first {
    width: 118px;
  }

  &--additional {
    width: 76px;
  }
}

.vacation-management-schedule__list-item__month-list {
  display: grid;
  grid-template-columns: repeat(12, 76px);
}

.vacation-management-page__tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.vacation-month-group {
  display: flex;
  gap: 4px;
}

.vacation-month-group-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  width: 20px;
  height: 42px;

  padding: 0 2px;

  border-radius: 4px;
}

.vacation-month-group-item-label {
  padding-bottom: 2px;

  border-bottom: 1px solid black;
}
