.company-select {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  min-height: 100vh;

  padding: 40px 0;

  background-color: var(--bg-secondary);
}

.company-select__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 110px;

  margin: 0 auto;
  padding: 0 32px;
}

.company-select__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  &--is-active {
    .company-select__list-item__image {
      border: 2px solid white;
    }
  }

  .list-item__click-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 300px;
  }
}

.company-select__list-item__image {
  width: 140px;
  height: 140px;

  border: 2px solid transparent;
  border-radius: 4px;
  object-fit: contain;

  transition: border var(--default-animation);
}

.company-select__list-item__name {
  margin-top: 12px;

  color: var(--white);

  text-align: center;
}

.company-select__new-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 150px;

  text-align: center;

  transition: color var(--default-animation);

  svg path {
    transition: stroke var(--default-animation);
    stroke: var(--text-secondary);
  }

  &:hover {
    p {
      color: white;
    }

    svg path {
      stroke: white;
    }
  }
}

.company-select__go-back {
  margin: 60px auto 0;

  color: var(--text-primary);
  background-color: var(--icon-grey);

  &:hover {
    background-color: white;
  }
}

.company-select__edit-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  margin-top: 20px;

  transition: color var(--default-animation);

  svg path {
    transition: stroke var(--default-animation);
  }

  &:hover {
    color: white;

    svg path {
      stroke: white;
    }
  }
}
