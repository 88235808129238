.schedule-selection-area {
  display: flex;

  width: fit-content;

  user-select: none;
}

.active {
  padding: 4px 0;

  background-color: blue;

  background-clip: content-box;
  border-right: unset;

  &:first-child {
    padding-left: 4px;
  }

  &:last-child {
    padding-right: 4px;

    border-right: 1px solid var(--stroke-grey);
  }
}

.schedule-row__form-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
