:root {
  --modal-transition-duration: 0.3s;
}

#modal-root {
  position: relative;
  z-index: calc(var(--page-header-z-index) + 2);
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;

  transition: opacity var(--modal-transition-duration) ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;

  width: max-content;

  padding: 48px;

  background-color: white;
  border-radius: 8px;

  transform: translate(-50%, -50%);

  &:focus {
    outline: none;
  }
}

.filter-modal {
  padding: 16px;
}

.modal--regular {
  width: 468px;
}

.l-modal-content {
  padding: 19px 24px;
}
