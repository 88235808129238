.contact-detail-page__header__breadcrumb {
  display: flex;
  justify-content: space-between;

  width: max-content;

  .arrow-right-icon {
    width: 24px;
    height: 24px;

    margin: 0 12px;
  }

  .active {
    padding: unset;

    background-color: unset;
  }
}

.contact-detail-page__header__delete-button {
  .trash_24x24-icon {
    margin-right: 8px;

    path {
      stroke: var(--text-primary);
    }
  }
}

.contact-detail-page__info-container {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.contact-detail-page__info__field {
  display: flex;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.contact-detail-page-info__field--note {
  width: 360px;

  padding: 12px;

  background-color: var(--bg-note);
}

.contact-detail-page__notes {
  margin-top: 50px;
}

.contact-detail-page__table {
  min-width: unset;

  margin-top: 24px;

  .table-header {
    grid-template-columns: 200px 1fr;

    text-align: start;
  }

  .table-row {
    grid-template-columns: 200px 1fr 72px;

    text-align: start;
  }

  .table-header-item__button {
    margin: unset;
  }
}

.contact-detail-page__edit-button {
  display: none;
}

.contact-detail-page__table__row {
  height: 75px;

  &:hover {
    .contact-detail-page__edit-button {
      display: flex;
    }
  }
}
