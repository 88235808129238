.page-navigation-item {
  display: inline-block;

  width: 100%;

  margin-bottom: 8px;
  padding: 8px 24px;

  color: var(--text-light);

  transition: all var(--default-animation);

  &:hover {
    border-right: 4px solid white;
  }

  &--is-active {
    color: var(--text-white);
    background-color: var(--button-primary);
    border-right: 4px solid var(--button-secondary-hover);
  }
}
