.credit-filter-form {
  .form-field__label {
    grid-template-columns: unset;
  }
}

.credit-filter-form__double-input {
  display: flex;
  align-items: center;
  gap: 8px;

  .input-container,
  .input {
    width: 80px;
  }
}
