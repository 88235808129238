@import "./typography-mixins";

.typography--h1 {
  @include typography--h1();
}

.typography--h2 {
  @include typography--h2();
}

.typography--h3 {
  @include typography--h3();
}

.typography--h4 {
  @include typography--h4();
}

.typography--h5 {
  @include typography--h5();
}

.typography--h6 {
  @include typography--h6();
}

.typography--h7 {
  @include typography--h7();
}

.typography--h8 {
  @include typography--h8();
}

.typography--h9 {
  @include typography--h9();
}

.typography--b1 {
  @include typography--b1();
}

.typography--b2 {
  @include typography--b2();
}

.typography--b3 {
  @include typography--b3();
}

.typography--b4 {
  @include typography--b4();
}

.typography--b5 {
  @include typography--b5();
}

.typography--b6 {
  @include typography--b6();
}

.text-color {
  &--primary {
    color: var(--text-primary);
  }

  &--secondary {
    color: var(--text-secondary);
  }

  &--light {
    color: var(--text-light);
  }

  &--white {
    color: var(--text-white);
  }

  &--blue {
    color: var(--text-blue);
  }

  &--info {
    color: var(--text-info);
  }

  &--error {
    color: var(--text-error);
  }
}

.text-underline {
  text-decoration-line: underline;
}
