.tank-payment-filter-form__checkbox-label {
  .form-field__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.tank-payment-filter-form__checkbox {
  width: 16px;
  height: 16px;
}
