.truck-detail-page__header__breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: max-content;

  .arrow-right-icon {
    width: 24px;
    height: 24px;

    margin: 0 12px;
  }

  .active {
    padding: unset;

    background-color: unset;
  }
}

.truck-detail-page__header__delete-button {
  .trash_24x24-icon {
    margin-right: 8px;

    path {
      stroke: var(--text-primary);
    }
  }
}

.truck-detail-page__meta {
  margin: 36px 0 48px;
}

.truck-detail-page__info__title {
  display: flex;
}

.truck-detail-page__info__edit-button {
  background-color: transparent;
}

.truck-detail-page__info-container {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
  margin-right: 40px;
}

.truck-detail-page__info__field {
  display: flex;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.truck-detail-page__table {
  min-width: 600px;

  margin-top: 24px;

  .table-header {
    grid-template-columns: 1fr 1fr 1fr;

    text-align: center;
  }
}

.truck-detail-page__table__row {
  .list-item__click-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    padding: 24px;

    background-color: white;

    text-align: center;

    transition: background-color var(--default-animation);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }

  &:hover {
    background-color: var(--button-dark-secondary);
  }
}
