@import "../../../typography/_typography-mixins.scss";

.tab__header {
  justify-content: space-around;
}

.tab-header-item {
  @include typography--h8();

  &--is-active {
    @include typography--h9();

    color: var(--text-main);
  }
}

.tab__body {
  margin-top: 24px;
}
