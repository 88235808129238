@import "../../../typography/_typography-mixins.scss";

.toggle {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  max-width: 400px;

  border: unset;
  border-radius: unset;
}

.toggle-item {
  background-color: unset;
}
