.tank-payment-detail-page__header__breadcrumb {
  display: flex;
  justify-content: space-between;

  width: max-content;

  .arrow-right-icon {
    width: 24px;
    height: 24px;

    margin: 0 12px;
  }

  .active {
    padding: unset;

    background-color: unset;
  }
}

.tank-payment-detail-page__header__delete-button {
  .trash_24x24-icon {
    margin-right: 8px;

    path {
      stroke: var(--text-primary);
    }
  }
}

.tank-payment-detail-page__info-container {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.tank-payment-detail-page__info__field {
  display: flex;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.tank-payment-detail-page-info__field--note {
  width: 360px;

  padding: 12px;

  background-color: var(--bg-note);
}

.tank-payment-detail-page__payment-history {
  margin-top: 36px;
}

.tank-payment-detail-page__table {
  min-width: unset;

  margin-top: 24px;
}

.tank-payment-detail-page__table__row {
  .tank-payment-detail-page__table__row__delete {
    opacity: 0;
  }

  &:hover {
    .tank-payment-detail-page__table__row__delete {
      opacity: 1;
    }
  }
}

.tank-payment-detail-page__table__row__delete {
  position: absolute;
  right: 50px;

  background-color: transparent;

  transition: opacity var(--default-animation), background-color var(--default-animation);

  &:hover {
    background-color: white;
  }
}
