.truck-page__table-container {
  overflow: auto;

  margin-top: 24px;
}

.truck-page__table {
  min-width: unset;

  .table-header {
    grid-template-columns: repeat(2, 1fr);

    text-align: center;
  }
}

.truck-page__table__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  padding: 24px;

  color: var(--text-primary);
  background-color: white;

  text-align: center;

  transition: background-color var(--default-animation);

  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }

  &:hover {
    background-color: var(--button-dark-secondary);

    .truck-page__table__row__delete-button {
      opacity: 1;
    }
  }

  &:visited {
    color: var(--text-primary);
  }
}
