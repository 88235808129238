@import "../../../core/ui/typography/_typography-mixins.scss";

.schedule-column {
  background-color: white;
}

.schedule-column__index {
  position: relative;

  width: 120px;
  height: 70px;

  background-color: white;
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);
}

.schedule-column__index__tage {
  position: absolute;
  top: 10px;
  right: 6px;
}

.schedule-column__index__name {
  position: absolute;
  bottom: 2px;
  left: 4px;
}

.schedule-column__index__diagonal-line {
  width: 138px;

  border-top: 1px solid var(--stroke-grey);

  transform: rotate(30deg);
  transform-origin: top left;
}

.schedule-column__row {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 70px;

  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);
}

.schedule-column__firm {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 43px;

  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);
}

.schedule-column__row-button {
  @include typography--h5();

  color: var(--text-primary);
}
