.employee-page-header {
  margin-bottom: 20px;
}

.employee-page-header__button-group {
  gap: 16px;
}

.document-icon {
  margin-right: 8px;
}
