.schedule-header {
  display: flex;

  width: fit-content;
}

.schedule-header__date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 120px;
  height: 70px;

  background-color: white;

  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);
}
