.toast-stack {
  width: 400px;
}

.toast {
  padding: 12px;

  color: white;
  background-color: var(--button-primary);
  border-radius: 4px;
}
