/* stylelint-disable declaration-no-important */
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-disable function-comma-space-after */

.selection-area__cell {
  position: relative;

  width: 120px;
  height: 70px;

  background-color: white;
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);

  cursor: pointer;

  &:before {
    position: absolute;
    top: 8px;
    left: 8px;

    width: 28px;
    height: 28px;

    background-image: url("../../core/ui/icon/plus-button.svg");
    opacity: 0;

    content: " ";

    transition: opacity var(--default-animation);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.selection-area__cell__has-status {
  padding: 4px 0;

  background-clip: content-box;
  border-right: unset;

  &:before {
    background-image: url("../../core/ui/icon/edit-frame.svg");
  }
}

.selection-area__cell__selected {
  padding: 0 !important;

  background-color: var(--tag-blue-3) !important;
  background-clip: content-box;
}

.selection-area__cell__weekend-day {
  background: repeating-linear-gradient(
    -45deg,
    var(--stroke-grey) 1px,
    var(--bg-primary) 5px
  );

  cursor: not-allowed;
  pointer-events: none;

  &:before {
    background-image: unset;
  }
}

.selection-area__cell__weekend-day--is-saturday {
  padding: 4px 0 4px 4px;

  background-color: var(--disabled-grey);
  background-clip: content-box;
  border-right: unset !important;

  cursor: not-allowed;
  pointer-events: none;
}

.selection-area__cell__weekend-day--is-sunday {
  padding: 4px 4px 4px 0;

  background-color: var(--disabled-grey);
  background-clip: content-box;

  cursor: not-allowed;
  pointer-events: none;
}

.tour-schedule {
  position: relative;

  .dot {
    position: absolute;
    top: 12px;
    right: 12px;

    width: 8px;
    height: 8px;

    background: var(--text-blue);
    border-radius: 100%;
  }
}

.tour-schedule__plate {
  position: absolute;
  bottom: 28px;
  left: 12px;

  max-width: 110px;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.tour-schedule__driver {
  position: absolute;
  bottom: 10px;
  left: 12px;
  z-index: 2;

  // max-width: 110px;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
