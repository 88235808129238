.admin-tab {
  display: inline-block;

  width: 100%;

  background-color: var(--white);
}

.admin-tab__table {
  min-width: unset;

  .table-header,
  .admin-tab__table__row {
    .list-item__click-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.admin-tab__table__row {
  padding: 0;
  
  .list-item__click-wrapper {
    padding: 20px 24px;
  }
}

.admin-tab__cta {
  margin: 32px 0 32px 44px;
}

.create-admin-form {
  .form-field__label {
    grid-template-columns: unset;
  }
}
