.credit-page__table-container {
  overflow: auto;

  margin-top: 24px;
}

.credit-page__table {
  min-width: 1100px;

  .table-header {
    grid-template-columns: repeat(7, minmax(120px, 1fr));

    text-align: center;
  }
}

.credit-page__table__row-container {
  display: grid;
  grid-template-columns: auto 150px;

  background-color: white;

  text-align: center;

  transition: background-color var(--default-animation);

  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }

  &:hover {
    background-color: var(--button-dark-secondary);
  }

  svg {
    margin-right: 30px;
  }
}

.credit-page__table__row {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, minmax(120px, 1fr));

  padding: 24px;

  &,
  &:visited {
    color: var(--text-primary);
  }
}

.credit-page__table__row__document {
  margin: 0 auto;
}
