.create-payment-form {
  display: flex;
  gap: 32px;

  margin-top: 24px;

  .form-field {
    width: 300px;
  }

  .form__button-group {
    margin-top: 60px;
  }

  .textarea {
    height: 120px;
  }

  .typeahead-select-header__tag-list {
    width: 300px;
  }
}
