.credit-detail-page__header__breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: max-content;

  .arrow-right-icon {
    width: 24px;
    height: 24px;

    margin: 0 12px;
  }

  .active {
    padding: unset;

    background-color: unset;
  }
}

.credit-detail-page__header__delete-button {
  .trash_24x24-icon {
    margin-right: 8px;

    path {
      stroke: var(--text-primary);
    }
  }
}

.credit-detail-page__info-header {
  display: flex;

  .button {
    background-color: transparent;
  }
}

.credit-detail-page__info-wrapper {
  margin: 30px 0;
}

.credit-detail-page__info-container {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.credit-detail-page__info__field {
  display: flex;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.credit-detail-page__payment-history {
  margin-top: 36px;
}

.credit-detail-page__table {
  .table-header {
    grid-template-columns: repeat(2, minmax(100px, 1fr)) 56px;
  }

  min-width: 600px;

  margin-top: 20px;
}

.credit-detail-page__table__row {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr)) 56px;

  padding: 24px;

  background-color: white;

  text-align: center;

  transition: background-color var(--default-animation);

  &:hover {
    background-color: var(--button-dark-secondary);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }

  // TODO: Remove it

  .button {
    margin: 0 auto;
  }
}
