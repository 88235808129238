.create-contact-form {
  display: flex;
  gap: 32px;

  margin-top: 24px;

  .input {
    width: 300px;
  }

  .textarea {
    height: 120px;
  }

  .typeahead-select-header__tag-list {
    width: 400px;
  }
}
