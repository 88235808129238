@import "../../core/ui/typography/_typography-mixins.scss";

.label {
  @include typography--b5();

  padding: 6px 8px;

  color: var(--text-primary);

  border: 1px solid var(--stroke-grey);
  border-radius: 4px;

  text-align: center;
}

.toggle-item {
  background-color: unset;

  &--is-selected {
    .toggle-item__label {
      .label {
        border: 1px solid var(--button-primary-hover);
      }
    }
  }
}
