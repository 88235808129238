.employee-form {
  display: flex;
  gap: 32px;

  margin-top: 24px;

  .form-field__label {
    grid-template-columns: unset;
  }
}

.employee-form__button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  margin-top: 36px;

  .form__button-group {
    margin-top: unset;
  }
}
