.loading {
  position: absolute;
  top: 50%;
  left: 50%;

  display: inline-block;

  width: 80px;
  height: 80px;

  transform: translate(-50%, -50%);
}

.loading div {
  position: absolute;

  display: block;

  width: 64px;
  height: 64px;

  box-sizing: border-box;

  margin: 8px;

  border: 3px solid black;
  border-color: black transparent transparent;
  border-radius: 50%;

  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
