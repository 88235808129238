.tooltip {
  &.__react_component_tooltip {
    width: auto;
    max-width: 360px;

    padding: 10px 20px;

    background: var(--button-primary-hover);
    border: none;
    border-radius: 12px;

    &.place-bottom {
      &:before,
      &:after {
        bottom: -6px;

        border-top-color: var(--button-primary-hover);
      }
    }
  }
}

.tooltip-content {
  color: var(--primary-button-text);
}
