.tank-payment-page__table-container {
  overflow: auto;

  margin-top: 24px;

  .label {
    width: max-content;

    margin: 0 auto;
  }
}

.tank-payment-page__table {
  min-width: 1100px;
}
