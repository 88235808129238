.tour-page__schedule {
  display: flex;
}

.tour-page__firm-row-container {
  display: flex;
}

.tour-page__firm-row {
  width: 120px;
  height: 43px;

  flex-shrink: 0;

  background-color: white;
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);
}
