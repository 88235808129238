.bill-page__table-container {
  overflow: auto;

  margin-top: 24px;
}

.bill-page__table {
  min-width: 1300px;

  .table-header {
    justify-content: center;
    grid-template-columns: 200px 180px 200px 220px 120px 120px 120px 120px;

    text-align: center;
  }
}

.bill-page__table__row {
  display: grid;
  justify-content: center;
  grid-template-columns: 200px 180px 200px 220px 120px 120px 120px 120px;

  padding: 24px;

  background-color: white;

  text-align: center;

  transition: background-color var(--default-animation);

  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }

  &:hover {
    background-color: var(--button-dark-secondary);

    .bill-page__table__row__delete-button {
      opacity: 1;
    }
  }
}

.bill-page__table__row__document {
  margin: 0 auto;
}

.bill-page__table__row__delete-button {
  background-color: var(--button-dark-secondary);

  opacity: 0;

  transition: all var(--default-animation);

  &:hover {
    background-color: white;
  }
}
