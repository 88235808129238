.page-content {
  position: relative;

  width: calc(100vw - var(--page-navigation-width));
  height: 100vh;

  overflow-y: scroll;

  margin-left: var(--page-navigation-width);
  padding: var(--page-content-padding);

  background-color: var(--bg-primary);

  transition: all var(--default-animation);

  &--wide {
    --page-navigation-width: 96px;
  }
}

.page-content--spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
