.vacation-planning-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vacation-planning-page__header__month {
  display: flex;
  align-items: center;

  .button {
    padding: 0;

    background-color: transparent;

    svg {
      height: 24px;
    }
  }
}

.vacation-planning-page__schedule {
  margin-top: 28px;
}

.vacation-planning-page__schedule__left-side {
  display: flex;

  .schedule-header__date {
    flex-shrink: 0;
  }
}

.vacation-planning-page__schedule-container {
  overflow: auto;
}
