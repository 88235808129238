.employee-detail-page-debt {
  width: 100%;
}

.employee-detail-page-debt__header {
  height: 52px;
}

.employee-detail-page-debt__field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.employee-detail-page-debt__table {
  height: 320px;

  margin-top: 20px;

  &.table {
    min-width: unset;
  }

  .table-body {
    height: 240px;

    overflow-y: scroll;
  }

  .table-header {
    grid-template-columns: 1fr 1fr;
  }
}

.employee-detail-page-debt__table__row {
  .list-item__click-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 20px 24px;

    background-color: white;

    text-align: center;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid var(--stroke-grey);
  }
}
