/* stylelint-disable declaration-no-important */

.vacation-schedule {
  display: grid;
  grid-template-columns: auto auto;
}

.vacation-schedule__cell-container {
  position: relative;
}

.vacation-schedule__cell__selected {
  padding: 0 !important;

  background-color: var(--tag-blue-3) !important;
  background-clip: content-box;
}

.vacation-schedule__cell {
  position: relative;

  width: 120px;
  height: 70px;

  background-color: white;
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);

  cursor: pointer;
}

.vacation-schedule__cell__has-status {
  padding: 4px 0;

  background-clip: content-box;
  border-right: unset;
}

.vacation-schedule__worker-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 70px;

  background-color: white;
  border-right: 1px solid var(--stroke-grey);
  border-bottom: 1px solid var(--stroke-grey);

  text-align: center;
}
