.settings-page__title {
  margin-bottom: 40px;

  text-align: center;
}

.settings-page__navigation {
  display: flex;
  justify-content: space-around;

  margin-bottom: 22px;
}
