@import "../../core/ui/typography/_typography-mixins.scss";

.create-company {
  height: 100vh;

  padding: 48px;

  background-color: white;
}

.create-company__form {
  display: flex;
  gap: 32px;

  .form-field__label {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: unset;
    gap: 4px;
  }
}

.create-company__add-logo-cta {
  &.file-input__button {
    padding: 12px;
  }

  svg {
    path {
      stroke: white;
    }
  }
}

.create-company--back-button {
  @include typography--h8();

  color: currentColor;
}

.create-company__button-group {
  justify-content: flex-end;

  margin-top: 24px;
}

.create-company__button-container {
  display: flex;
  justify-content: space-between;
}
