.edit-tour-form {
  width: 400px;

  padding: 12px 8px;

  background-color: white;

  .typeahead-select-header__tag-list {
    width: 100%;
  }

  .create-tour-form__button-group {
    margin-top: 24px;
  }
}

.edit-tour-form__date-range {
  .form-field__label {
    display: flex;
  }

  .date-input-container {
    width: 120px;
  }
}

.edit-tour-form__form-field {
  &--date {
    grid-template-columns: 120px 1fr 12px 1fr;
  }

  &--label {
    .form-field__label {
      grid-template-columns: 60px 1fr;
    }
  }
}

.edit-tour-form__form-field--label-toggle {
  grid-template-columns: 44px 54px 105px;
  gap: 12px;
}

.edit-tour-form__form-field--label-note {
  .form-field__label {
    align-items: flex-start;
  }

  .create-tour-form__note-textarea {
    width: unset;
  }
}

.edit-tour-form__plus-button {
  padding: unset;
}
