.create-truck-form {
  .form-field__label {
    grid-template-columns: unset;
    gap: unset;

    width: 100%;
  }
}

.create-truck-from__title {
  margin-bottom: 24px;
}

.create-truck-form__button-group {
  display: flex;
  justify-content: flex-end;

  margin-top: 24px;
}
