.employee-detail-page-working__table-container {
  overflow: auto;
}

.employee-detail-page-working__table {
  margin-top: 20px;

  .table-header,
  .employee-detail-page-working__table__row {
    display: grid;
    grid-template-columns: repeat(7, 140px);
    gap: 8px;

    padding: 20px 12px;
  }

  .table-header,
  .table-row {
    width: 1040px;
  }

  .input-container--type-time {
    width: 130px;
  }
}

.employee-detail-page-working__table__row {
  &:hover {
    .employee-detail-page-working__edit-button {
      opacity: 1;
    }
  }
}

.employee-detail-page-working__table__row__edit-button {
  margin-left: 50px;
  padding: 0;
}

.employee-detail-page-working__table__row__expenses {
  margin: 0 auto;

  &.input-container,
  .input {
    width: 80px;
  }
}

.employee-detail-page-working__table__row__submit {
  margin: 0 auto;

  background-color: transparent;
}

.duration-input-group {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 4px;
  }

  .input-container {
    width: max-content;
  }

  .input {
    width: 44px;
  }
}

.employee-detail-page-working__edit-button {
  margin-left: 10px;
  
  background-color: transparent;
  opacity: 0;

  transition: opacity var(--default-animation);
}
