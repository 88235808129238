@import "../../typography/_typography-mixins.scss";

* {
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  &:before {
    box-sizing: border-box;
  }

  &:after {
    box-sizing: border-box;
  }
}

ul,
ol,
li {
  list-style-type: none;
}

body {
  @include typography--b1();

  color: var(--grey-80);

  background: var(--background-color);
}

input[type="checkbox"] {
  accent-color: black;
}

a {
  // color: var();
  text-decoration: none;

  // &:hover,
  // &:focus {
  //   color: var(--cyan-50);
  // }
}
