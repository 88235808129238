.employee-detail-page-info-container {
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.employee-detail-page-info__field {
  display: flex;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.employee-detail-page-info__field--note {
  padding: 12px;

  background-color: var(--bg-note);
}
